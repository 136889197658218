import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Userauth from './Auth/Userauth'
import Profile from '../Pages/Profile/Profile'
import AllTravellers from '../Pages/Travellers/AllTravellers'




const AllRoutes = () => {
  const navigate = useNavigate();

  const isLoggedInString = localStorage.getItem('isLoggedIn') || ''
  const isLoggedIn = isLoggedInString ?? JSON.parse(isLoggedInString);



  return (
    <Routes>
      <Route
        path="/"
        element={
          isLoggedIn ? (
            <AllTravellers />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Userauth defaultState={'signin'} />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/main" element={<AllTravellers />} />
      <Route path="/groups" element={<h1>Groups</h1>} />
      <Route path="/recent" element={<h1>Recents</h1>} />
    </Routes>
  );
};

export default AllRoutes;

