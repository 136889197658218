import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ToastModuleProps } from '../../types/Signup';
import { useToastModule } from '../../context/ToastContextProvider';




const ToastModule = () => {

  const {toastMessage , toastSeverity , isOpen ,hideToast} = useToastModule()
  const [open, setOpen] = React.useState(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    hideToast()
  };

  React.useEffect(() => {
    setOpen(isOpen); 
  }, [toastMessage,toastSeverity]);

  return (
    <>
      <Snackbar open={open} autoHideDuration={2000}  onClose={handleClose}>
        <Alert
            onClose={handleClose}
            severity={toastSeverity}
            variant="filled"
            sx={{ width: '100%' }}
        >
         {toastMessage ? toastMessage :"Something Went Wrong!"}
        </Alert>
      </Snackbar>
    </>
  )
}


export default ToastModule

