import React, { useContext, useRef, useState } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  MutedLink,
  MutedText,
  StyledButtonSubmit,
} from "../styledComponents/Common";
import { Marginer } from "../styledComponents/marginer";
import { AccountContext } from "../context/accountContext";
import { useNavigate } from "react-router-dom";

import { Backdrop, Button, CircularProgress, TextField } from "@mui/material";




export default function ForgetPassword() {

  const { switchToSignin } = useContext(AccountContext);
  const [isLoading, setIsLoading] = React.useState(false)
  const redirect = useNavigate()


  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = ()=>{
    setIsLoading(true)
  };

  const handleClickForForget = ()=>{
    console.log()
  }
  


  return (
    <BoxContainer>
      <FormContainer>
        <TextField color="secondary" type="email" label="Email" name="email" id="email" placeholder="Email"  value={formData.email}
          onChange={handleInputChange} />
      </FormContainer>
      <Marginer direction="vertical" margin={10} />
      <MutedText onClick={()=>{handleClickForForget()}}>A verification mail will be sent!</MutedText>
      <Marginer direction="vertical" margin="1.6em" />
      {/* <StyledButtonSubmit onClick={()=>handleSubmit()} type="submit"><span>Send Verification</span></StyledButtonSubmit> */}
      <Button variant="contained"  onClick={()=>{handleSubmit()}} type="submit" fullWidth disableElevation> Send Verification </Button> 
      <Marginer direction="vertical" margin="1em" />
      <MutedText>
        Go back to
            <BoldLink onClick={switchToSignin}>
                Login
            </BoldLink>
      </MutedText>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </BoxContainer>
  );
}