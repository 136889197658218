import styled ,{keyframes} from "styled-components";


export const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  
  @media (min-width: 1080px) {
    height: 100%;
    justify-content: center;
    width:30%;
    align-items:center;
    
  }
  @media (min-width: 446px) and (max-width:1079px) {
    align-items:center;
    height: 100%;
    justify-content: center;
    width:70%;
  }
  
`;

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
    gap:18px;
`;

export const MutedLink = styled.a`
  font-size: 14px;
  color:rgb(113 109 109);
  font-weight: 500;
  text-decoration: none;
  cursor:pointer;
  text-align: right;
  width: 100%;
  &:hover{
    color:#c31432; 
    font-size: 16px;
  }
`;

export const MutedText = styled.p`
  font-size: 14px;
  color:rgb(113 109 109);
  font-weight: 500;
  text-decoration: none;
`;

export const MutedTextNoMargin = styled.p`
  font-size: 14px;
  color:var(--primary-text-color-dark);
  font-weight: 500;
  text-decoration: none;
  margin:0;
`;

export const BoldLink = styled.a`
  font-size: 18px;
  color: #c31432;
  font-weight: 500;
  text-decoration: none;
  margin: 0 4px;
  user-select:none;
  cursor:pointer;

  &:hover{
    font-size:22px
  }
`;

export const BoldText = styled.h3`
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
  user-select:none;
  cursor:pointer;
  margin:0;
  color:var(--primary-text-color-dark);
  &:hover{
    font-size:22px
  }
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  border: 1px solid rgba(200, 200, 224, 0.9);
  padding: 15px 10px;
  transition: all 200ms ease-in-out;
  font-size: 16px;
  border-radius:5px;

  &::placeholder {
    color: rgb(113 109 109);
  }

  &:focus {
    outline:1px solid #c31432;
  }

`;



const hoverAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;




// StyledButton component using styled-components
export const StyledButton = styled.button`
  background: #c31432; 
  background: -webkit-linear-gradient(to right, #240b36, #c31432); 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  position: relative;
  overflow: hidden;
  

  &:hover {
    animation: ${hoverAnimation} 0.3s ease-out forwards;
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    pointer-events: none;
  }

  &:hover:after {
    width: 0;
    height: 0;
  }

  span {
    position: relative;
    z-index: 1;
  }`
;

export const StyledButtonSubmit = styled.button`
  width: 100%;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  position: relative;
  overflow: hidden;
  background: #c31432; 
  background: -webkit-linear-gradient(to right, #240b36, #c31432); 
  background: linear-gradient(to right, #240b36, #c31432); 

  // &:hover {
  //   animation: ${hoverAnimation} 0.3s ease-out forwards;
  // }

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    pointer-events: none;
  }

  &:hover:after {
    width: 0;
    height: 0;
  }

  span {
    position: relative;
    z-index: 1;
  }`
;

export const StyledIcon = styled.i`
  background: var(--primary-theme-color); 
  // background: -webkit-linear-gradient(to right, #240b36, #c31432); 
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  position: relative;
  overflow: hidden;
  display:flex;
  justify-content:center;
  gap:5px;
  align-items:center;
  

  &:hover {
    animation: ${hoverAnimation} 0.3s ease-out forwards;
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    pointer-events: none;
  }

  &:hover:after {
    width: 0;
    height: 0;
  }

  span {
    position: relative;
    z-index: 1;
  }`
;

export const MainPageBox = styled.div`
  padding:5px 10px;
  height:90vh;
  overflow:auto;
  padding-bottom:8px;
  background-color:"var(--primary-bg-color-dark)"}
`