import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import AllTravellers from '../../Pages/Travellers/AllTravellers';
import { Box, Paper } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DirectionsRailwayOutlinedIcon from '@mui/icons-material/DirectionsRailwayOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import Profile from '../../Pages/Profile/Profile';
import { useNavigate } from 'react-router-dom';


// Example components to render
const Favorites = () => <div>Groups Content</div>;
const Nearby = () => <div>Recents Content</div>;

export default function BottomNavigator() {
  const [value, setValue] = React.useState('main');
  const redirect = useNavigate()

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setTimeout(()=>{
      redirect(`/${newValue}`)
    },0)
    
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '10vh',width:"100%" ,backgroundColor:"var(--primary-bg-color-dark)"}}>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,height:"10vh" }} elevation={5}>
      <BottomNavigation sx={{ width: '100%',height:"80px" }} value={value} onChange={handleChange} className="svg-size">
      <BottomNavigationAction
          label="Saarthi"
          value="main"
          icon={<DirectionsRailwayOutlinedIcon />}
        />
        <BottomNavigationAction
          label="Groups"
          value="groups"
          icon={<Diversity3OutlinedIcon />}
        />
        <BottomNavigationAction
          label="Recents"
          value="recent"
          icon={<RestoreIcon />}
        />

        <BottomNavigationAction
          label="Profile"
          value="profile"
          icon={<AccountCircleOutlinedIcon />}
        />
      </BottomNavigation>
      </Paper>
    </Box>
  );
}
