import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { Backdrop, Badge, CircularProgress, MenuItem, Typography } from '@mui/material';
import Notification from '@mui/icons-material/Notifications';
import logo from "../../assets/logo512.png"
import MoreIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { appURL } from '../../Services/AppUrl';
import { useToastModule } from '../../context/ToastContextProvider';
import { useNavigate } from 'react-router-dom';
import { WbSunny , DarkMode} from '@mui/icons-material';

const settings = ['Profile', 'Logout','Toggle Theme'];


function logoutAxios(){
  return axios.post(appURL+'api/authentication/logout',{},{ withCredentials: true })

}



function TopBar({toggleTheme,isDarkMode}:any) {
  const { showToast } = useToastModule();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = React.useState(false)
  const redirect = useNavigate();
  const [theme , setTheme] = React.useState(localStorage.getItem('saarthii-theme'))
  
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (value:string) => {
    if(value=='Logout'){
      setIsLoading(true)
      logoutAxios().then((res)=>{
          setIsLoading(false)
          redirect("/login")
          localStorage.removeItem("isLoggedIn")
          showToast("Logged Out Successfully","success")
      }).catch((err:any)=>{
        setIsLoading(false)
        showToast(err?.response?.data?.msg,"error")
      })
    }

    setAnchorElUser(null);
  };

  const handleToggleTheme =()=>{
    const currentTheme = localStorage.getItem('saarthii-theme') || 'light';
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark'; 
    toggleTheme(newTheme);  
    setTheme(newTheme)    
    localStorage.setItem('saarthii-theme',newTheme)
  }



  return (
    <AppBar position="static" sx={{backgroundColor:'var(--primary-bg-color-dark)',color:"#9a283c",position:"sticky",top:0,zIndex:111111,boxShadow:"none"}}>
      <Container maxWidth="xl" sx={{paddingLeft:"6px",paddingRight:"6px"}}> 
        <Toolbar disableGutters  sx={{justifyContent:"space-between"}}>
        <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            SAARTHII
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
          <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={7} color="error">
                <Notification />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              onClick={handleToggleTheme}>
                {theme !== 'dark' ? <WbSunny /> : <DarkMode/>}
            </IconButton>
            <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={handleOpenUserMenu}
          >
            <MoreIcon />
          </IconButton>
          <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={()=>handleCloseUserMenu(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AppBar>
  );
}
export default TopBar;
