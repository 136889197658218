import * as React from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';


interface ChipData {
  idx: number;
  name: string;
  status?:boolean
}

const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        deleteIcon: {
          color: 'white',
        },
      },
    },
  },
});

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsArray() {
  const [chipData, setChipData] = React.useState<readonly ChipData[]>([
    { idx: 1, name: 'Reading' },
    { idx: 2, name: 'Traveling' },
    { idx: 3, name: 'Cooking' },
    { idx: 4, name: 'Sports' },
    { idx: 5, name: 'Fitness' },
    { idx: 6, name: 'Photography' },
    { idx: 7, name: 'Art' },
    { idx: 8, name: 'Music' },
    { idx: 9, name: 'Dancing' },
    { idx: 10, name: 'Movies' },
    { idx: 11, name: 'Gaming' },
    { idx: 12, name: 'Fashion' },
    { idx: 13, name: 'Foodie' },
    { idx: 14, name: 'Hiking' },
    { idx: 15, name: 'Camping' },
    { idx: 16, name: 'Pets' },
    { idx: 17, name: 'Volunteering' },
    { idx: 18, name: 'Gardening' },
    { idx: 19, name: 'Technology' },
    { idx: 20, name: 'History' },
    { idx: 21, name: 'Science' },
    { idx: 22, name: 'Politics' },
    { idx: 23, name: 'Languages' },
    { idx: 24, name: 'Singing' },
    { idx: 25, name: 'Playing an Instrument' },
    { idx: 26, name: 'Board Games' },
    { idx: 27, name: 'DIY Projects' },
    { idx: 28, name: 'Watching TV Series' },
    { idx: 29, name: 'Collecting' },
    { idx: 30, name: 'Cycling' },
    { idx: 31, name: 'Running' },
    { idx: 32, name: 'Meditation' },
    { idx: 33, name: 'Astrology' },
    { idx: 34, name: 'Fashion Design' },
    { idx: 35, name: 'Interior Design' },
    { idx: 36, name: 'Anime' },
    { idx: 37, name: 'Role-Playing Games' },
    { idx: 38, name: 'Comic Books' },
    { idx: 39, name: 'Documentaries' },
    { idx: 40, name: 'Writing' }
    ]);


  const handleSelection = (chipToSelect: ChipData,status:boolean) => () => {
    setChipData(chipData.map(chip =>{
      if(chip.idx===chipToSelect.idx){
        return {...chip,status}
      }
      return chip
    }))
  }
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipData.map((data) => {
        let icon;
        return (
          <ListItem key={data.idx}>
            {(data['status'])  ? <Chip
              icon={icon}
              label={data.name}
              sx={{backgroundColor: "var(--primary-theme-color)",color:"white !important",
              '&:hover': {
                backgroundColor: 'var(--primary-theme-color)',
              }}}
              onClick={handleSelection(data,false)}
            />: <Chip
            icon={icon}
            onClick={handleSelection(data,true)}
            label={data.name}/>}

          </ListItem>
        );
      })}
    </Paper>
  );
}
