export interface ISignupInterface{
    name:string,
    email:string,
    password:string,
    mobileNumber?:string 
}

export interface ILoginInterface{
    email:string,
    password:string
}
export interface IVerifyInterface{
    email:string,
    otp:number
}


export interface ToastModuleProps {
    msg: string;       
    severity: 'info' | 'warning' | 'error' | 'success'; 
    closable?: boolean;  
    isOpen: boolean;     
  }





export const travellersData = [
    {
        name: 'Aamir', username: 'mythofAamir', id: '1a', journeys: [
            {
                "pnrNumber": "4101616323",
                "dateOfJourney": "Aug 3, 2024 12:50:49 AM",
                "trainNumber": "03260",
                "trainName": "SMVB DNR SPL",
                "sourceStation": "NGP",
                "destinationStation": "DNR",
                "reservationUpto": "DNR",
                "boardingPoint": "NGP",
                "journeyClass": "SL",
                "numberOfpassenger": 4,
                "chartStatus": "Chart Not Prepared", "concessionOpted": false,
                "forGoConcessionOpted": false,
                "passengerIcardFlag": false,
                "childBerthFlag": false,
                "passengerNationality": "IN",
                "passengerQuota": "GN",
                "passengerCoachPosition": 0,
                "waitListType": 0,
                "bookingStatusIndex": 0,
                "bookingStatus": "CNF",
                "bookingCoachId": "S5",
                "bookingBerthNo": 25,
                "bookingBerthCode": "LB",
                "bookingStatusDetails": "CNF/S5/25/LB",
                "currentStatusIndex": 0,
                "currentStatus": "CNF",
                "currentCoachId": "",
                "currentBerthNo": 0,
                "currentStatusDetails": "CNF"
            }
        ]
    }, {
        name: 'Salman', username: 'mythofSalman', id: '2a', journeys: [{
            "pnrNumber": "4101615211",
            "dateOfJourney": "Aug 24, 2024 8:10:37 PM",
            "trainNumber": "12631",
            "trainName": "NELLAI SF EXP",
            "sourceStation": "MS",
            "destinationStation": "TEN",
            "reservationUpto": "TEN",
            "boardingPoint": "MS",
            "journeyClass": "SL",
            "numberOfpassenger": 2,
            "chartStatus": "Chart Not Prepared", "concessionOpted": false,
            "forGoConcessionOpted": false,
            "passengerIcardFlag": false,
            "childBerthFlag": false,
            "passengerNationality": "IN",
            "passengerQuota": "SS",
            "passengerCoachPosition": 0,
            "waitListType": 0,
            "bookingStatusIndex": 0,
            "bookingStatus": "CNF",
            "bookingCoachId": "S3",
            "bookingBerthNo": 41,
            "bookingBerthCode": "LB",
            "bookingStatusDetails": "CNF/S3/41/LB",
            "currentStatusIndex": 0,
            "currentStatus": "CNF",
            "currentCoachId": "",
            "currentBerthNo": 0,
            "currentStatusDetails": "CNF"
        }]
    }, {
        name: 'Shahrukh', username: 'mythofShahrukh', id: '3a', journeys: [{
            "pnrNumber": "4101619199",
            "dateOfJourney": "Aug 4, 2024 10:10:33 AM",
            "trainNumber": "16054",
            "trainName": "TPTY MAS EXP",
            "sourceStation": "TPTY",
            "destinationStation": "MAS",
            "reservationUpto": "MAS",
            "boardingPoint": "TPTY",
            "journeyClass": "CC",
            "numberOfpassenger": 5,
            "chartStatus": "Chart Not Prepared", "concessionOpted": false,
            "forGoConcessionOpted": false,
            "passengerIcardFlag": false,
            "childBerthFlag": false,
            "passengerNationality": "IN",
            "passengerQuota": "GN",
            "passengerCoachPosition": 0,
            "waitListType": 0,
            "bookingStatusIndex": 0,
            "bookingStatus": "CNF",
            "bookingCoachId": "C1",
            "bookingBerthNo": 2,
            "bookingBerthCode": "NC",
            "bookingStatusDetails": "CNF/C1/2/NC",
            "currentStatusIndex": 0,
            "currentStatus": "CNF",
            "currentCoachId": "",
            "currentBerthNo": 0,
            "currentStatusDetails": "CNF"
        }]
    }, {
        name: 'Prabhas', username: 'mythofPrabhas', id: '5a', journeys: [{
            "dateOfJourney": "Aug 14, 2024 11:52:19 PM",
            "trainNumber": "12509",
            "trainName": "GUWAHATI EXP",
            "sourceStation": "KJM",
            "destinationStation": "BZA",
            "reservationUpto": "BZA",
            "boardingPoint": "KJM",
            "journeyClass": "SL",
            "numberOfpassenger": 2,
            "chartStatus": "Chart Not Prepared", "concessionOpted": false,
            "forGoConcessionOpted": false,
            "passengerIcardFlag": false,
            "childBerthFlag": false,
            "passengerNationality": "IN",
            "passengerQuota": "GN",
            "passengerCoachPosition": 0,
            "waitListType": 0,
            "bookingStatusIndex": 0,
            "bookingStatus": "RAC",
            "bookingCoachId": "",
            "bookingBerthNo": 27,
            "bookingStatusDetails": "RAC/27",
            "currentStatusIndex": 0,
            "currentStatus": "RAC",
            "currentCoachId": "",
            "currentBerthNo": 10,
            "currentStatusDetails": "RAC/10"
        }]
    }
]