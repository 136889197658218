
// Adding These Files to ensure that 

import React, { useEffect } from 'react';
import './App.css';
import AllRoutes from './views/AllRoutes';
import styled from 'styled-components';
import {  ToastModuleContextProvider } from './context/ToastContextProvider';
import ToastModule from './components/ToastModule/ToastModule';
import TopBar from './utils/TopBar/TopBar';
import BottomNavigator from './views/Home/BottomNavigatior';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';


const AppContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100vh;
  background-color:var(--primary-bg-color-dark)
`;


const theme = createTheme({
  palette: {
    primary: {
      main: '#a52b3f', 
    },
    background: {
      default: '#ffffff',
      paper: '#1e201c', 
      
    },
    text: {
      primary: '#aaa7a7',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(to right, #240b36, #c31432)',
          color: 'white',
          padding:"0.7rem"
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'var(--primary-bg-color-dark)', 
          color:'var(--primary-text-color-dark)'
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background:'var(--primary-bg-color-dark)', 
          color:'var(--primary-text-color-dark)'
        },
      },
    },
    MuiChip:{
      styleOverrides: {
        root: {
          background:'var(--primary-bg-color-dark)', 
          color:'var(--primary-text-color-dark) !important',
        },
      },
    },
    MuiFormLabel:{
      styleOverrides:{
        root:{
          color:'var(--primary-text-color-dark) !important',
        }
      }
    },
    MuiTextField:{
      styleOverrides:{
        root:{
          color:'var(--primary-text-color-dark) !important',
        }
      }
    },
    MuiBottomNavigation:{
      styleOverrides: {
        root: {
          background:'var(--primary-bg-color-dark)', 
          color:'var(--primary-text-color-dark) !important',
        },
      },
    },
    MuiFormControlLabel:{
      styleOverrides:{
        root:{
          color:'var(--primary-text-color-dark) !important',
        }
      }
    },
    MuiInput:{
      styleOverrides:{
        root:{
          color:'var(--primary-text-color-dark) !important',
        }
      }
    },
    MuiMenu:{
      styleOverrides:{
        root:{
          background:'var(--primary-text-color-dark) !important',
        }
      }
    }
  },
});



function App() {
  let isDarkMode = localStorage.getItem('saarthii-theme') || 'dark'

  const toggleTheme = (theme:string)=>{
    if(theme == 'dark'){
      document.documentElement.style.setProperty('--primary-theme-color', '#a52b3f');
      document.documentElement.style.setProperty('--primary-bg-color-dark', '#131313');
      document.documentElement.style.setProperty('--primary-text-color-dark', '#cacaca');
      document.documentElement.style.setProperty('--primary-border-color-dark', '#fcfcfc');
    }else{
      document.documentElement.style.setProperty('--primary-theme-color', '#a52b3f');
      document.documentElement.style.setProperty('--primary-bg-color-dark', '#f3f3f3');
      document.documentElement.style.setProperty('--primary-text-color-dark', '#000000');
      document.documentElement.style.setProperty('--primary-border-color-dark', '#322828');
    }
    
  }

  useEffect(()=>{
    toggleTheme(isDarkMode)
  },[])

  
  

  const location = useLocation();
  const hideTopBarAndNavRoutes = ['/login'];

  const shouldHideTopBarAndNav = hideTopBarAndNavRoutes.includes(location.pathname);

  return (
    <ThemeProvider theme={theme}>
    <ToastModuleContextProvider>
      <AppContainer>
          {!shouldHideTopBarAndNav &&  <TopBar toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>}
            <AllRoutes/>
          {!shouldHideTopBarAndNav &&  <BottomNavigator />}
      </AppContainer>
      <ToastModule/>
    </ToastModuleContextProvider>
    </ThemeProvider>
    
  );
}

export default App;
