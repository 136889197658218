import React from 'react';
import Divider from '@mui/material/Divider';
import { Avatar, Box } from '@mui/material';
import { BoldText, MutedText, MutedTextNoMargin, StyledIcon } from '../../styledComponents/Common';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Marginer } from '../../styledComponents/marginer';

const ProfileTopSection = () => {
  return (
    <Box sx={{width:"100%",display:"flex",justifyContent:"space-around",alignItems:"center",}}>
        <Box>
            <Avatar sx={{width:"100px",height:"100px"}} alt="Remy Sharp"  />
        </Box>
        <Box>
            <BoldText>Update Your Picture</BoldText>
            <Marginer direction="vertical" margin={5}/>
            <MutedTextNoMargin>Upload a image under 2MB</MutedTextNoMargin>
            <Marginer direction="vertical" margin={10}/>
            <Box sx={{display:"flex"}}>
                <StyledIcon><CloudUploadIcon/>Upload</StyledIcon>
                {/* <MutedTextNoMargin color='red'>Delete current Image</MutedTextNoMargin> */}
            </Box>
        </Box>
    </Box>
  )
}

export default ProfileTopSection