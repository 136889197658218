import React from 'react'
import { travellersData } from '../../types/Signup'
import Traveller from '../../components/Traveller/Traveller'
import { Box, Button, ToggleButton } from '@mui/material'
import { CheckCircleRounded, Height } from '@mui/icons-material'
import { MainPageBox, StyledButton } from '../../styledComponents/Common'
import { useNavigate } from 'react-router-dom'



const AllTravellers = () => {

    const [travellers, setTravellers] = React.useState(travellersData)
    const [selected, setSelected] = React.useState(false);
    const navigate = useNavigate()
    const isJourneySimilar = (traveller: any) => {
        const myJournyes = ['16054', '12631']
        return traveller.journeys.find(({ trainNumber }: any) => myJournyes.includes(trainNumber))
    }

    const filterData = () => {
        setSelected(!selected);
        setTravellers(
            !selected ?
                travellersData.filter((traveller) => isJourneySimilar(traveller)) : travellersData
        )
    }

    const handleLogout = ()=>{
        localStorage.removeItem('token')
        navigate("/login")
    }

    return (
        <MainPageBox>
            <div>{travellers.map((traveller) => {
                return <Traveller details={traveller} key={traveller.id}></Traveller>
            })}</div>
        </MainPageBox>
    )
}



export default AllTravellers


