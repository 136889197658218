// import React from "react";
// import styled from "styled-components";

// const HorizontalMargin = styled.span`
//   display: flex;
//   width: ${({ margin }) =>
//     typeof margin === "string" ? margin : `${margin}px`};
// `;

// const VerticalMargin = styled.span`
//   display: flex;
//   height: ${({ margin }) =>
//     typeof margin === "string" ? margin : `${margin}px`};
// `;

// function Marginer(props) {
//   const { direction } = props;

//   if (direction === "horizontal") return <HorizontalMargin {...props} />;
//   else {
//     return <VerticalMargin {...props} />;
//   }
// }

// // Marginer.defaultProps = {
// //   direction: "horizontal",
// // };

// export { Marginer };


import React from "react";

function HorizontalMargin(props) {
  const { margin } = props;
  const width = typeof margin === "string" ? margin : `${margin}px`;

  return (
    <span style={{ display: "flex", width }}>
      {props.children}
    </span>
  );
}

function VerticalMargin(props) {
  const { margin } = props;
  const height = typeof margin === "string" ? margin : `${margin}px`;

  return (
    <span style={{ display: "flex", height }}>
      {props.children}
    </span>
  );
}

function Marginer(props) {
  const { direction } = props;

  if (direction === "horizontal") {
    return <HorizontalMargin {...props} />;
  } else {
    return <VerticalMargin {...props} />;
  }
}

export { Marginer };
