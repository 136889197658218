import React, { useContext,useState } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  MutedLink,
  MutedText,
  StyledButton,
  StyledButtonSubmit,
  StyledIcon,
} from "../styledComponents/Common";
import { Marginer } from "../styledComponents/marginer";
import { AccountContext } from "../context/accountContext";
import { ILoginInterface } from "../types/Signup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Backdrop, Box, Button, CircularProgress, TextField } from "@mui/material";
import { appURL } from "../Services/AppUrl";
import { useToastModule } from "../context/ToastContextProvider";
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';




function signupAxios(payload:ILoginInterface){
  return axios.post(appURL+'api/authentication/login',payload,{ withCredentials: true })

}

function googleAuthCallback(){
  // return axios.get(appURL + 'api/authentication/google')
  window.location.href = appURL + "api/authentication/google/";
  return {success : true };
}

export function Login() {

  // State and Funtions
  const { switchToSignup,switchToReset } = useContext(AccountContext);
  const { showToast } = useToastModule();
  const [isLoading, setIsLoading] = useState(false)
  const redirect = useNavigate()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });



  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = ()=>{
    setIsLoading(true)
    signupAxios(formData).then((res)=>{
      if(res.data.redirect){
        setIsLoading(false)
        redirect('/')
        localStorage.setItem("isLoggedIn",JSON.stringify('token'))
      }
    }).catch((err:any)=>{
      setIsLoading(false)
      showToast(err?.response?.data?.msg, "error")
    })
  };

  const handleClickForForget = ()=>{
    switchToReset()
  }

  const handleGoodleAuth = ()=>{
    googleAuthCallback()
  }


  return (
    <BoxContainer>
      <FormContainer>
        <TextField color="secondary" type="email" label="Email" name="email" id="email" placeholder="Email"  value={formData.email}
          onChange={handleInputChange} />
        <TextField color="secondary" type="password" label="Password" name="password" id="password" placeholder="Password"  value={formData.password}
          onChange={handleInputChange}/>
      </FormContainer>
      <Marginer direction="vertical" margin={10} />
      <MutedLink onClick={()=>{handleClickForForget()}} style={{color:"#c31432"}}>Forget your password?</MutedLink>
      <Marginer direction="vertical" margin="1.3em" />
      {/* <StyledButtonSubmit onClick={()=>handleSubmit()} type="submit"><span>Sign In</span></StyledButtonSubmit> */}
      <Button variant="contained"  onClick={()=>handleSubmit()} type="submit" fullWidth disableElevation> Sign In </Button>
      <Marginer direction="vertical" margin="0.5em" />
      <MutedText>
        or Sign in with
      </MutedText>
      <Marginer direction="vertical" margin="0.5em" />
      <Box sx={{ display: 'flex',gap:'1rem' }}>
        <StyledIcon><GoogleIcon onClick={handleGoodleAuth}/></StyledIcon> 
        <StyledIcon> <FacebookIcon/></StyledIcon> 
        <StyledIcon> <XIcon/></StyledIcon> 
      </Box>
      <Marginer direction="vertical" margin="0.7em" />
      
      <MutedText>
        Don't have an accoun?{" "}
        <BoldLink style={{cursor:"pointer"}} onClick={switchToSignup} >
          Sign Up
        </BoldLink>
      </MutedText>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </BoxContainer>
  );
}
