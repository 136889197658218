import React, { useState } from 'react';
import ProfileTopSection from '../../components/ProfileTopSection/ProfileTopSection';
import { Marginer } from '../../styledComponents/marginer';
import { BoldText, FormContainer, MainPageBox, StyledButton, StyledButtonSubmit, StyledIcon } from '../../styledComponents/Common';
import { Box, Button, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, SelectChangeEvent, TextField } from '@mui/material';
import ChipsArray from '../../components/ChipsArray/ChipsArray';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import ProfileAccordion from '../../components/ProfileAccordian/ProdileAccordian';

const Profile = () => {
  const genderList = [
    "Male",
    "Female",
    "Unknown"
  ];

  const callMe = () =>{
    console.log('Save Data...',userDetails)
  }

  const [userDetails,setUserDetails] = useState<any>({
    name:'',email:'',mobile:'',about:'',gender:''
  })

  const onValueChange = (e:any,id:string)=>{
    setUserDetails({...userDetails,[id] :e.target.value})
  }

  return (
    <MainPageBox>       
      <Marginer  direction="vertical" margin={"1rem"} />
      <ProfileTopSection/>
      <Marginer  direction="vertical" margin={"2rem"} />
      <FormContainer>
        <TextField color="secondary" type="text" label="Your Name" name="name" id="name" value={userDetails.name} onChange={(e)=>onValueChange(e,'name')} placeholder="Enter Your Name" />
        <TextField color="secondary" type="email" label="Email" name="email" id="email" onChange={(e)=>onValueChange(e,'email')} placeholder="Enter Email" disabled={true} value={"test@gmail.com"}/>
        <TextField color="secondary"  type="number" label="Mobile Number" name="about" value={userDetails.mobile} id="mobile" onChange={(e)=>onValueChange(e,'mobile')} placeholder="Enter Your Mobile Number" />
        <TextField color="secondary" multiline rows={3} type="text" label="Your About" value={userDetails.about} name="about" id="about" onChange={(e)=>onValueChange(e,'about')} placeholder="Type Something..." />
        <FormLabel sx={{marginBottom:"-6px"}} id="demo-row-radio-buttons-group-label">Gender</FormLabel>
          <RadioGroup onChange={(e)=>onValueChange(e,'gender')} 
          sx={{justifyContent:"flex-start"}}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {/* TODO Change RADIO STyle Class To Global */}
            <FormControlLabel value="male"  control={<Radio sx={{
    color: "#c31432",
    '&.Mui-checked': {
      color: "#c31432",
    },
  }} />} label="Male" />
            <FormControlLabel value="female"  control={<Radio  sx={{
    color: "#c31432",
    '&.Mui-checked': {
      color: "#c31432",
    },
  }} />} label="Female" />
            <FormControlLabel value="other"  control={<Radio sx={{
    color: "#c31432",
    '&.Mui-checked': {
      color: "#c31432",
    },
  }}  />} label="Other" />
          </RadioGroup>
        <FormLabel id="demo-row-radio-buttons-group-label" sx={{marginBottom:"-6px"}}>Select Hobbies & Interests</FormLabel>
        <ChipsArray></ChipsArray>
      </FormContainer>
      
      <Marginer  direction="vertical" margin={"2rem"} />
      <Button variant="contained"  onClick={()=>callMe()} type="submit" fullWidth disableElevation> Save </Button>
      <Marginer  direction="vertical" margin={"2rem"} />
      <ProfileAccordion/>
      
    </MainPageBox>
    
  );
};

export default Profile;
