import React, { createContext, useState, useContext } from 'react';

export interface ToastContextType {
  showToast: (msg: string, severity: 'success' | 'info' | 'warning' | 'error') => void;
  hideToast: () => void;
  isOpen:boolean;
  toastMessage:string;
  toastSeverity:'success' | 'info' | 'warning' | 'error';

}

const ToastModuleContext = createContext<ToastContextType | undefined>(undefined);

export const useToastModule = () => {
  const context = useContext(ToastModuleContext);
  if (!context) {
    throw new Error('useToastModule must be used within a ToastModuleContextProvider');
  }
  return context;
};

export const ToastModuleContextProvider: React.FC<any> = ({ children }) => {
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastSeverity, setToastSeverity] = useState<'success' | 'info' | 'warning' | 'error'>('success');
  const [isOpen, setIsOpen] = useState(false);

  const showToast = (msg: string, severity: 'success' | 'info' | 'warning' | 'error') => {
    setToastMessage(msg);
    setToastSeverity(severity);
    setIsOpen(true);
  };

  const hideToast = () => {
    setToastMessage('');
    setToastSeverity(toastSeverity);
    setIsOpen(false);
  };


  const contextValue: ToastContextType = {
    showToast,
    hideToast,toastMessage,toastSeverity,isOpen
  };

  return (
    <ToastModuleContext.Provider value={contextValue}>
      {children}
    </ToastModuleContext.Provider>
  );
};
