import React from 'react';
import './Traveller.css';



const Traveller = ({ details }: any) => {
    return (
        <div className="traveller">
            <h1>{details.name}</h1>
            <div className="journeys">
                <h2>Journeys</h2>
                {details.journeys.map((journey: any, index: any) => (
                    <div key={index} className="journey">
                        <p><strong>Train Number:</strong> {journey.trainNumber}</p>
                        <p><strong>Class:</strong> {journey.journeyClass}</p>
                        <p><strong>Date of Journey:</strong> {journey.dateOfJourney}</p>
                        <p><strong>Train Name:</strong> {journey.trainName}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Traveller;
