import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { AccountContext } from "../../context/accountContext";
import { Signup } from "../../Pages/Signup";
import ForgetPassword from "../../Pages/ForgetPassword";
import { Login } from "../../Pages/Login";


const BoxContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-bg-color-dark);
  box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden; 
  gap:30px;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.8em;
  padding-bottom: 5em;
  @media (min-width: 445px) {
    display:none;
  }
`;

const BackDrop = styled(motion.div)`
  width: 160%;
  height: 550px;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  // transform: rotate(60deg);
  top: -290px;
  background: #c31432; 
  background: -webkit-linear-gradient(to right, #240b36, #c31432); 
  background: linear-gradient(to right, #240b36, #c31432); 
  z-index:111;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.h2`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.24;
  color: #fff;
  z-index: 10;
  margin: 0;
  text-align:right;
  z-index:111111;
`;

const SmallText = styled.h5`
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  z-index: 10;
  margin: 0;
  margin-top: 7px;
  text-align:right;
  z-index:111111;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.8em;
  @media (min-width: 446px) {
    height: 100%;
    align-items:center;
  }
`;

const backdropVariants = {
  expanded: {
    width: "450%",
    height: "1600px",
    borderRadius: "20%",
    transform: "rotate(200deg)",
  },
  collapsed: {
    width: "160%",
    height: "512px",
    borderRadius: "50%",
    transform: "rotate(80deg)",
  },
};

const expandingTransition = {
  type: "spring",
  duration: 2,
  stiffness: 70,
};

export default function Userauth({defaultState}) {
  const [isExpanded, setExpanded] = useState(false);
  const [active, setActive] = useState(defaultState);

  const playExpandingAnimation = () => {
    setExpanded(true);
    setTimeout(() => {
      setExpanded(false);
    }, expandingTransition.duration * 1000 - 1500);
  };

  const switchToSignup = () => {
    playExpandingAnimation();
    setTimeout(() => {
      setActive("signup");
    }, 600);
  };

  const switchToSignin = () => {
    playExpandingAnimation();
    setTimeout(() => {
      setActive("signin");
    }, 600);
  };
  const switchToReset = () => {
    playExpandingAnimation();
    setTimeout(() => {
      setActive("resetPass");
    }, 600);
  };

  const contextValue = { switchToSignup, switchToSignin , switchToReset };

  return (
    <AccountContext.Provider value={contextValue}>
      <BoxContainer>
        <TopContainer>
          <BackDrop
            initial={false}
            animate={isExpanded ? "expanded" : "collapsed"}
            variants={backdropVariants}
            transition={expandingTransition}
          />
          {active === "signin" && (
            <HeaderContainer>
              <HeaderText>Welcome</HeaderText>
              <HeaderText>Back</HeaderText>
              <SmallText>Please sign-in to continue!</SmallText>
            </HeaderContainer>
          )}
          {active === "signup" && (
            <HeaderContainer>
              <HeaderText>Create</HeaderText>
              <HeaderText>Account</HeaderText>
              <SmallText>Please sign-up to continue!</SmallText>
            </HeaderContainer>
          )}
          {active === "resetPass" && (
            <HeaderContainer>
              <HeaderText>Reset</HeaderText>
              <HeaderText>Password</HeaderText>
              <SmallText>Please Reset your password!</SmallText>
            </HeaderContainer>
          )}
        </TopContainer>
        <InnerContainer>
          {active === "signin" && <Login />}
          {active === "signup" && <Signup/>}
          {active === "resetPass" && <ForgetPassword/>}
        </InnerContainer>
      </BoxContainer>
    </AccountContext.Provider>
  );
}