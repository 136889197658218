import React, { useContext, useRef, useState } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  MutedText,
  StyledButtonSubmit
} from "../styledComponents/Common";
import { Marginer } from "../styledComponents/marginer";
import { AccountContext } from "../context/accountContext";
import { ISignupInterface, IVerifyInterface } from "../types/Signup";
import axios from "axios";
import { Backdrop, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { OTP } from "../utils/OTP";
import styled from "styled-components";
import { appURL } from "../Services/AppUrl";
import { useToastModule } from "../context/ToastContextProvider";


const SmallText = styled.h5`
  color: gray;
  font-weight: 500;
  font-size: 11px;
  z-index: 10;
  margin: 0;
  margin-top: 7px;
  text-align:left;
`;


function signupAxios(payload: ISignupInterface) {
  return axios.post(appURL+'api/authentication/signup', payload,{ withCredentials: true })
}


function verifyOTPAxios(payload: IVerifyInterface) {
  return axios.post(appURL+'user/verify', payload,{ withCredentials: true })
}


export function Signup() {

  const [otp, setOtp] = React.useState('')
  const [isEmailSent, setIsEmailSent] = React.useState(false)
  const [userData, setUserData] = React.useState<ISignupInterface>()
  const [isLoading, setIsLoading] = React.useState(false)
  const { switchToSignin } = useContext(AccountContext);
  const { showToast } = useToastModule();
  const [formData, setFormData] = useState({
    name: '',
    email:'',
    password: '',
    mobileNumber:''
  });



  // Function and Event Handlers


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleVerifyOTP = () => {
    let payload = {
      email: userData?.email as string,
      otp: Number(otp)
    }
    verifyOTPAxios(payload).then((res: any) => {
      setIsLoading(true)
      if (res) {
        switchToSignin()
        setIsLoading(false)
        showToast(res.data?.msg , "success")
      }
    }).catch((err:any)=>{
      console.log(err)
      showToast(err?.response?.data?.msg , "error")
    })
  }

  const handleSubmit = () => {
    setIsLoading(true)
    setUserData(formData)
    signupAxios(formData).then((res) => {
      setIsLoading(false)
      setIsEmailSent(true)
      showToast(res.data?.msg , "success")
    }).catch((err: any) => {
      showToast(err?.response?.data?.msg , "error")
      setIsLoading(false)
    })
  };

    




 

  return (
    <BoxContainer>
      <FormContainer>
        <TextField color="secondary" type="text" required name="name" placeholder="Full Name" label="Name"  value={formData.name}
          onChange={handleInputChange} />
        <TextField color="secondary" type="email" required name="email" placeholder="@mail.com" label="Email"  value={formData.email}
          onChange={handleInputChange} />
        <TextField color="secondary" type="password" required name="password" placeholder="Password" label="Password"  value={formData.password}
          onChange={handleInputChange}/>
        <TextField color="secondary" type="text" name="mobileNumber" placeholder="Mobile Number" label="Mobile Number"  value={formData.mobileNumber}
          onChange={handleInputChange} />
        {isEmailSent && <Grid margin={'0 !important'}>
              <SmallText>Enter Your OTP</SmallText>
              <OTP separator={<span> </span>} value={otp} onChange={setOtp} length={6} />
            </Grid>}
      </FormContainer>
      <Marginer direction="vertical" margin={16} />
      {!isEmailSent ? <Button variant="contained"  onClick={()=>{handleSubmit()}} type="submit" fullWidth disableElevation> Create Account </Button> : <Button variant="contained"  disabled={otp.length <6} onClick={()=>{handleVerifyOTP()}} type="submit" fullWidth disableElevation> Verify</Button>}
      <Marginer direction="vertical" margin="1em" />
      <MutedText>
        Already have an account?
        <BoldLink onClick={switchToSignin}>
          Sign In
        </BoldLink>
      </MutedText>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </BoxContainer>
  );
}